import React from 'react'
import Layout from '../components/layout'
import { Container, Row, Col, Form, Button} from 'react-bootstrap'
import '../styles/contact.css'
// import { Link } from 'gatsby'

const contact = () => {
    return (
        <Layout>
            <div className="contact_background p_background_img">
                <div className="cover-img-overlay">
                    <Container>
                        <Row>
                        <Col xl={6} lg={6} md={6} >
                            <div className="p_vertical_line tr"></div>
                                <h1 className="p_head_blog">contact us</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            
            {/* <div class="p_btm_svg"></div> */}

            <Container style={{marginTop: "50px"}}>
                <Container>
                <Row style={{marginBottom:"50px",display:"flex"}}>
                    
                    <Col className="col-12 col-sm-12 col-md-6" style={{display:"flex", justifyItems:"center"}}>
                        <Form accept-charset="utf-8" action="https://formspree.io/xrgobpww" method="post"
                            style={{width:"400px"}}>
                            <Form.Group controlId="exampleForm.ControlInput1">
                                
                                <Form.Control type="Text" placeholder="Name" name="name" id="full-name" required/>
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlInput2">
                                
                                <Form.Control type="email" placeholder="Email" name="_replyto" id="email-address" required/>
                            </Form.Group>
                            
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                
                                <Form.Control as="textarea" rows="3" placeholder="message" name="message" id="message" required/>
                            </Form.Group>

                            <input type="hidden" name="_subject" id="email-subject" value="Contact Form Submission"></input>
                            <Button variant="primary" type="submit" className="button">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                    <Col className="contact-col col-12 col-sm-12 col-md-6" style={{textAlign:"start",padding:"10px 0 10px 0",display:"flex",alignItems:"center"}}>
                            {/* <div style={{marginLeft:"40px"}}>
                                <div>
                                <Link to="/"><div style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                                    <img src="../../mdi_location_on.svg" alt="location icon" className="ftr_icon" style={{marginRight:"10px"}}></img>
                                    <h5>157 Carlls Path, Deer Park,<br/> New York,USA 11729</h5>
                                </div></Link>
                                
                                <Link to="/"><div style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                                    <img src="../../mdi_location_on.svg" alt="location icon" className="ftr_icon" style={{marginRight:"10px"}}></img>
                                    <h5>Opp. Govt Law College,<br/>Calicut, Kerala</h5>
                                </div></Link>
                                <Link to="/"><div style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                                    <img src="../../el_phone.svg" alt="phone icon" className="ftr_icon" style={{marginRight:"10px"}}></img>
                                    <h5>+91 000000006 <br/>+91 0097898676</h5>
                                </div></Link>
                                
                                <Link to="/"><div style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
                                    <img src="../../gridicons_mail.svg" alt="email icon" className="ftr_icon" style={{marginRight:"10px"}}></img>
                                    <h5>info@ausflugholidays.com</h5>
                                </div></Link>
                                </div>
                            </div> */}
                            <Row style={{padding:"0 16px 0 16px",display:"flex", alignItems:"center"}}>
                            <Col sm={6} md={6}>
                                <h4 className="ausflugHeadings" style={{color:"rgb(0, 143, 204)"}}>HEAD OFFICE</h4>
                                <p> Ausflug Holidays <br/>
                                    157 Charlls Path <br/>
                                    Deer Park, New York <br/>
                                    USA 11729 <br/>
                                    +1 (516 416 - 0252)</p>
                            </Col>
                            <Col sm={6} md={6}>
                                <h4 className="ausflugHeadings" style={{color:"rgb(0, 143, 204)"}}>ADMINISTRATIVE OFFICE</h4>
                                <p> Ausflug Holidays <br/>
                                    Jas Building <br/>
                                    Vellimadukunnu, Calicut<br/>
                                    Kerala, 6773012, India <br/>
                                    +91 9633 574 815</p>
                            </Col>
                            
                        </Row>
                    </Col>
                    <Col style={{}}>
                        <h4 className="ausflugHeadings" style={{color:"rgb(0, 143, 204)", margin:"60px 0 26px 0"}}>BRANCHES</h4>
                        <Row>
                            
                                
                            <Col sm={6} md={6} lg={3}>
                                <p> Ausflug Holidays <br/>
                                    152 Collins Meadow<br/>
                                    Harlow <br/>
                                    Essex <br/>
                                    UK <br/>
                                    +44 7501004219</p>
                            </Col>
                            <Col sm={6} md={6} lg={3}>
                                <p> Ausflug Holidays <br/>
                                    Darsait <br/>
                                    Street 1330<br/>
                                    Po Box 81, Pc 100<br/>
                                    Muscat, Oman<br/>
                                    +968 92671568</p>
                            </Col>
                            <Col sm={6} md={6} lg={3}>
                                <p> Ausflug Holidays <br/>
                                    Pragathi Nagar <br/>
                                    Hyderabad <br/>
                                    500060 <br/>
                                    +91 9985511376</p>
                            </Col>
                            <Col sm={6} md={6} lg={3}>
                                <p> Ausflug Holidays <br/>
                                    Dalgate <br/>
                                    Srinagar<br/>
                                    190001 <br/>
                                    +91 9906636451</p>
                            </Col>
                            
                        </Row>
                    </Col>
                    

                        
                </Row>
                </Container>

                <Container>
                    <div>
                        
                    </div>
                </Container>



                {/* <div className="" style={{display:"flex",justifyContent:"center"}}>
                    <div>
                    <Col className="map-col">
                            <iframe title="google map"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3912.5742066254584!2d75.81709381480542!3d11.292677891972465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTHCsDE3JzMzLjYiTiA3NcKwNDknMDkuNCJF!5e0!3m2!1sen!2sin!4v1601455427566!5m2!1sen!2sin"width="100%" height="450" frameborder="0" style={{borderRadius:"20px"}} allowfullscreen="" aria-hidden="false" ></iframe>
                            
                        </Col>
                    </div>
                </div> */}
            </Container>
        </Layout>
    )
}
export default contact